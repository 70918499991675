import React, { useContext, useEffect, useState } from "react";

const MobileMaxWidth = 480;

export enum EnvKind {
  Desktop = "desktop",
  Mobile = "mobile",
}

export interface SizeVariants {
  mobile: number;
  desktop: number;
}

interface IEnvContext {
  value: EnvKind;
}

const EnvContext = React.createContext<IEnvContext>({
  value: EnvKind.Desktop,
});

export const useEnv = (): EnvKind => {
  return useContext(EnvContext).value;
};

interface EnvProviderProps {
  initialValue: EnvKind;
  children: React.ReactNode;
}
export const EnvProvider = ({ initialValue, children }: EnvProviderProps) => {
  const [env, setEnv] = useState(initialValue);

  useEffect(() => {
    const handler = (e: UIEvent) => {
      setEnv(
        window.innerWidth > MobileMaxWidth ? EnvKind.Desktop : EnvKind.Mobile
      );
    };

    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return (
    <EnvContext.Provider value={{ value: env }}>{children}</EnvContext.Provider>
  );
};
