import React from "react";
import styled from "styled-components";

import { Footer, Header } from "../component/mobile";
import {
  Badge,
  HorizontalLayout,
  MainPopup,
  Push,
  Space,
  SubjectIcon,
  VerticalLayout,
} from "../component";
import { MapURL, PhoneNumber } from "../constant";

export const MobilePage = () => {
  const onScrollTo = (id: string) => {
    const y =
      document.getElementById(id)!.getBoundingClientRect().top +
      window.pageYOffset +
      -10;

    window.scrollTo({
      top: y,
      behavior: id === "contact" ? "instant" : "smooth",
    });

    if (id === "contact") {
      window.open(`tel:${PhoneNumber}`);
    }
  };

  return (
    <Container>
      <Header />

      <MainBanner src="/mobile/banner.jpg" />

      <div
        style={{
          display: "flex",
          width: "100%",
          fontSize: "15px",
          fontWeight: "bold",
          padding: "10px 0px",
        }}
      >
        <Push />
        <VerticalLayout
          style={{ alignItems: "center" }}
          onClick={() => onScrollTo("schedule")}
        >
          <img src="/common/clock.png" style={{ height: "21px" }} />
          <Space height={10} />
          진료시간
        </VerticalLayout>
        <Push />
        <VerticalLayout
          style={{ alignItems: "center" }}
          onClick={() => onScrollTo("contact")}
        >
          <img src="/common/phone2.png" style={{ height: "21px" }} />
          <Space height={10} />
          예약•상담 문의
        </VerticalLayout>
        <Push />
        <VerticalLayout
          style={{ alignItems: "center" }}
          onClick={() => onScrollTo("location")}
        >
          <img src="/common/location2.png" style={{ height: "21px" }} />
          <Space height={10} />
          오시는 길
        </VerticalLayout>
        <Push />
      </div>

      <div
        style={{
          background: "#f3f5fb",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Space height={50} />

        <img
          src="/mobile/elephant.png"
          style={{ width: "125px", margin: "0px auto" }}
        />
        <Space height={25} />

        <div
          style={{ textAlign: "center", fontSize: "24px", fontWeight: "800" }}
        >
          <span style={{ color: "#eb8307" }}>서울참</span>
          <span style={{ color: "#17316e" }}>어린이치과</span>
          <span style={{ fontSize: "15px" }}>는,</span>
        </div>
        <Space height={17} />

        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "15px",
            lineHeight: "2",
            color: "#17316e",
          }}
        >
          한 아이, 한 아이 참마음으로 참된 치료를 하는, <br />
          소아치과 전문의가 진료하는 어린이 치과입니다.
          <br />
          치과에 내원하는 아이들이 편안하고
          <br />
          즐거운 공간으로 느끼고 인식하도록 노력하겠습니다.
        </div>
        <Space height={30} />
      </div>

      <div style={{ background: "#f0f5e1", padding: "40px 21px 10px 21px" }}>
        <SectionTitle color="#788a3d">의료진 소개</SectionTitle>
        <Space height={35} />

        <HorizontalLayout
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src="/mobile/doctor.png" style={{ width: "123px" }} />
          <Space width={14} />

          <VerticalLayout style={{ transform: "translateY(-25px)" }}>
            <div style={{ fontSize: "23px", fontWeight: "bold" }}>김아람</div>
            <Space height={12} />
            <div style={{ fontSize: "14px" }}>원장 / 소아치과 전문의</div>
          </VerticalLayout>
        </HorizontalLayout>
        <div
          style={{
            borderRadius: "15px",
            background: "#fcfef6",
            padding: "23px 19px",
            transform: "translateY(-30px)",
          }}
        >
          <div
            style={{ fontSize: "15px", fontWeight: "bold", color: "#1f1f1f" }}
          >
            약력
          </div>
          <Space height={16} />
          <div
            style={{
              fontSize: "15px",
              lineHeight: "1.6",
              color: "#4d4d4d",
            }}
          >
            보건복지부 인증 소아치과 전문의
            <br />
            한국과학기술원(KAIST) 졸업
            <br />
            서울대학교 치의학대학원 치의학과 졸업
            <br />
            서울대학교 치과병원 인턴
            <br />
            서울대학교 치과병원 소아치과 레지던트
            <br />
            서울대학교 치과대학 치의학 석사
            <br />
            서울대학교 치과대학 소아치과학 박사 수료
            <br />
            대한소아치과학회 정회원
            <br />
            대한장애인치과학회 정회원
          </div>
        </div>
      </div>

      <div style={{ background: "#fffcf5", padding: "50px 21px" }}>
        <SectionTitle>치과 둘러보기</SectionTitle>
        <Space height={25} />

        <img
          src="/mobile/photo.png"
          style={{ width: "100%", height: "auto" }}
        />
      </div>

      <div style={{ background: "#fff", padding: "40px 21px" }}>
        <SectionTitle>진료과목</SectionTitle>
        <Space height={25} />

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
          }}
        >
          <SubjectIcon imageSrc="/common/subject_1.png">
            구강검진
            <br />
            영유아 구강검진
          </SubjectIcon>
          <SubjectIcon imageSrc="/common/subject_2.png">
            충치예방치료
            <br />
            충치치료
          </SubjectIcon>
          <SubjectIcon imageSrc="/common/subject_3.png">
            수면진정치료
          </SubjectIcon>
          <SubjectIcon imageSrc="/common/subject_4.png">
            성장기 교정치료
          </SubjectIcon>
          <SubjectIcon imageSrc="/common/subject_5.png">외상치료</SubjectIcon>
          <SubjectIcon imageSrc="/common/subject_6.png">
            구강내소수술
          </SubjectIcon>
        </div>
      </div>

      <div style={{ background: "#fff", padding: "40px 21px 10px" }}>
        <SectionTitle id="schedule">진료안내</SectionTitle>
        <Space height={25} />

        <div
          style={{
            background: "#8faa3b",
            borderRadius: "10px",
            padding: "26px 16px",
          }}
        >
          <HorizontalLayout align="center">
            <img src="/common/info_clock.png" style={{ width: "21px" }} />
            <div
              style={{
                color: "#e2ff5c",
                fontSize: "17px",
                fontWeight: "bold",
                marginLeft: "12px",
              }}
            >
              진료시간
            </div>
          </HorizontalLayout>
          <Space height={15} />

          <div
            style={{
              background: "white",
              borderRadius: "10px",
              padding: "15px",
            }}
          >
            <HorizontalLayout align="center">
              <Badge backgroundColor="#8faa3b" color="#e2ff5c">
                평일
              </Badge>
              <Space width={12} />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#6f8823",
                }}
              >
                오전 10:00 – 오후 06:30
              </div>
            </HorizontalLayout>
            <Space height={5} />

            <HorizontalLayout align="center">
              <Badge backgroundColor="#386baa" color="#8cddff">
                토요일
              </Badge>
              <Space width={12} />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#386baa",
                  transform: "translateY(10px)",
                }}
              >
                오전 09:00 – 오후 02:00
                <br />
                (토요일은 점심시간 없음)
              </div>
            </HorizontalLayout>
            <Space height={15} />

            <HorizontalLayout align="center">
              <Badge backgroundColor="#e97f2d" color="#fee7ba">
                점심시간
              </Badge>
              <Space width={12} />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#e97f2d",
                }}
              >
                오후 12:30 – 오후 02:00
              </div>
            </HorizontalLayout>
            <Space height={20} />

            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "800",
                transform: "translateX(-15px)",
              }}
            >
              일요일, 공휴일은 <span style={{ color: "#c70000" }}>휴진</span>
              입니다.
              <div style={{ position: "relative" }}>
                <img
                  src="/common/deco1.png"
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "-30px",
                    width: "30px",
                  }}
                />
              </div>
            </div>
          </div>
          <Space height={53} />

          <HorizontalLayout align="center">
            <img src="/common/info_calendar.png" style={{ width: "21px" }} />
            <div
              id="contact"
              style={{
                color: "#e2ff5c",
                fontSize: "17px",
                fontWeight: "bold",
                marginLeft: "12px",
              }}
            >
              예약제도 안내
            </div>
          </HorizontalLayout>
          <Space height={15} />

          <div style={{ color: "white", lineHeight: "1.47" }}>
            서울참어린이치과는,
            <br />
            어린이 한명 한명에게 최선을 다해 진료하기 위하여, 보호자분들의
            대기시간을 단축시켜 드리기 위하여,{" "}
            <span
              style={{
                background: "#799426",
                padding: "3px",
                fontWeight: "bold",
              }}
            >
              진료 예약제
            </span>
            를 시행하고 있습니다.
          </div>
          <Space height={40} />

          <HorizontalLayout align="center">
            <img src="/common/info_phone.png" style={{ width: "21px" }} />
            <div
              style={{
                color: "#e2ff5c",
                fontSize: "17px",
                fontWeight: "bold",
                marginLeft: "12px",
              }}
            >
              예약•상담 문의
            </div>
          </HorizontalLayout>
          <Space height={15} />

          <div
            style={{
              color: "white",
              fontSize: "27px",
              fontWeight: "bold",
              textShadow: "2px 2px #7e9929",
            }}
            onClick={() => window.open(`tel:${PhoneNumber}`)}
          >
            051-701-2879
          </div>
        </div>
      </div>

      <div style={{ background: "#fff", padding: "40px 21px" }}>
        <SectionTitle id="location">오시는길</SectionTitle>
        <Space height={25} />

        <img
          src="/mobile/map.png"
          style={{ width: "100%", height: "auto" }}
          onClick={() => window.open(MapURL, "_blank")}
        />
        <Space height={20} />

        <VerticalLayout style={{ gap: "20px" }}>
          <HorizontalLayout>
            <img
              src="/common/location.png"
              style={{ width: "28px", height: "28px" }}
            />
            <Space width={5} />
            <VerticalLayout style={{ marginTop: "5px" }}>
              <div style={{ fontWeight: "bold", color: "#45c1df" }}>주소</div>
              <Space height={14} />
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  lineHeight: "1.33",
                }}
              >
                부산광역시 해운대구 해운대로 814,
                <br />
                세종월드프라자 A동 4층
                <br />
                <span style={{ color: "#52ac00" }}>
                  지하철 2호선 장산역 3번 출구
                </span>
              </div>
            </VerticalLayout>
          </HorizontalLayout>

          <HorizontalLayout>
            <img
              src="/common/car.png"
              style={{ width: "28px", height: "28px" }}
            />
            <Space width={5} />
            <VerticalLayout style={{ marginTop: "5px" }}>
              <div style={{ fontWeight: "bold", color: "#45c1df" }}>
                주차 안내
              </div>
              <Space height={14} />
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  lineHeight: "1.33",
                }}
              >
                동 건물 지하주차장
                <br />
                주차권에 병원 도장을 찍어가시면 동 건물
                <br />
                지하주차장 2시간 무료입니다
              </div>
            </VerticalLayout>
          </HorizontalLayout>

          <HorizontalLayout>
            <img
              src="/common/phone.png"
              style={{ width: "28px", height: "28px" }}
            />
            <Space width={5} />
            <VerticalLayout style={{ marginTop: "5px" }}>
              <div style={{ fontWeight: "bold", color: "#45c1df" }}>
                예약, 상담 문의
              </div>
              <Space height={14} />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "1.33",
                }}
                onClick={() => window.open(`tel:${PhoneNumber}`)}
              >
                TEL. 051-701-2879
              </div>
            </VerticalLayout>
          </HorizontalLayout>
        </VerticalLayout>
      </div>

      <Footer />

      <MainPopup />
    </Container>
  );
};

const Container = styled.div``;

const MainBanner = styled.img.attrs({})`
  width: 100%;
  height: auto;
`;

const SectionTitle = styled.div<{ color?: string }>`
  font-size: 17px;
  font-weight: bold;

  color: ${({ color }) => color ?? "#313131"};
`;
