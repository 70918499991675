import React from "react";
import { styled } from "styled-components";

import { Space } from "../layout";

export const Footer = () => {
  return (
    <Container>
      <Logo src="/mobile/logo.png" />
      <Space height={22} />
      상호명 : 서울참어린이치과의원 대표자명 : 김아람
      <br />
      사업자 등록번호 : 350-90-00283
      <br />
      주소 : 부산시 해운대구 해운대로 814 세종월드프라자 4층 <br />
      전화 : 051-701-2879
      <br />
      FAX : 051-701-2878
      <Space height={33} />
      Copyright <b>ⓒ서울참어린이치과의원</b> All rights reserved
    </Container>
  );
};

const Container = styled.div`
  background-color: #f4f4f4;

  color: #454545;

  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.7px;

  padding: 26px 18px;
`;

const Logo = styled.img.attrs({})`
  width: 130px;
  height: auto;
`;
