import React from "react";
import styled from "styled-components";

import { Header } from "../component/desktop/Header";
import { Footer } from "../component/desktop/Footer";
import {
  Badge,
  HorizontalLayout,
  MainPopup,
  Push,
  Space,
  SubjectIcon,
  VerticalLayout,
} from "../component";
import { MapURL, PhoneNumber } from "../constant";

export const DesktopPage = () => {
  const onScrollTo = (id: string) => {
    const y =
      document.getElementById(id)!.getBoundingClientRect().top +
      window.pageYOffset +
      -40;

    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <Header />

      <MainBanner src="/desktop/banner.png" />

      <div
        style={{
          display: "flex",
          width: "100%",
          color: "#4d4d4d",
          fontSize: "24px",
          fontWeight: "bold",
          padding: "47px 0px",
        }}
      >
        <Push />
        <HorizontalLayout
          style={{ alignItems: "center", cursor: "pointer" }}
          onClick={() => onScrollTo("schedule")}
        >
          <img src="/desktop/schedule.png" style={{ height: "56px" }} />
          <Space width={15} />
          진료시간
        </HorizontalLayout>
        <Push />
        <HorizontalLayout
          style={{ alignItems: "center", cursor: "pointer" }}
          onClick={() => onScrollTo("contact")}
        >
          <img src="/desktop/phone.png" style={{ height: "56px" }} />
          <Space width={15} />
          예약•상담 문의
        </HorizontalLayout>
        <Push />
        <HorizontalLayout
          style={{ alignItems: "center", cursor: "pointer" }}
          onClick={() => onScrollTo("location")}
        >
          <img src="/desktop/location.png" style={{ height: "56px" }} />
          <Space width={15} />
          오시는 길
        </HorizontalLayout>
        <Push />
      </div>

      <div
        style={{
          background: "#f3f5fb",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Space height={72} />
        <Max1280>
          <HorizontalLayout fill align="center">
            <div style={{ fontSize: "36px", fontWeight: "800" }}>
              <span style={{ color: "#eb8307" }}>서울참</span>
              <span style={{ color: "#17316e" }}>어린이치과</span>
              <span style={{ fontSize: "24px" }}>는,</span>
            </div>
            <Push />

            <img src="/mobile/elephant.png" style={{ width: "187px" }} />
          </HorizontalLayout>
          <Space height={17} />

          <div
            style={{
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "2",
              color: "#17316e",
            }}
          >
            한 아이, 한 아이 참마음으로 참된 치료를 하는, <br />
            소아치과 전문의가 진료하는 어린이 치과입니다.
            <br />
            치과에 내원하는 아이들이 편안하고 즐거운 공간으로 느끼고 인식하도록
            노력하겠습니다.
          </div>
        </Max1280>
        <Space height={93} />
      </div>

      <div style={{ background: "#f0f5e1", padding: "60px 21px 40px 21px" }}>
        <Max1280>
          <SectionTitle color="#788a3d">의료진 소개</SectionTitle>
          <Space height={35} />

          <HorizontalLayout style={{ justifyContent: "center" }}>
            <VerticalLayout
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src="/mobile/doctor.png" style={{ width: "200px" }} />
              <Space width={14} />

              <HorizontalLayout
                align="end"
                style={{ transform: "translateY(-25px)" }}
              >
                <div
                  style={{
                    wordBreak: "keep-all",
                    fontSize: "32px",
                    fontWeight: "800",
                  }}
                >
                  김아람
                </div>
                <Space width={12} />
                <div
                  style={{
                    wordBreak: "keep-all",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  원장 / 소아치과 전문의
                </div>
              </HorizontalLayout>
            </VerticalLayout>
            <Space width={120} />

            <div
              style={{
                borderRadius: "30px",
                padding: "43px 50px",
                background: "#fcfef6",
              }}
            >
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "#1f1f1f",
                }}
              >
                약력
              </div>
              <Space height={16} />
              <div
                style={{
                  fontSize: "20px",
                  lineHeight: "1.8",
                  color: "#4d4d4d",
                }}
              >
                보건복지부 인증 소아치과 전문의
                <br />
                한국과학기술원(KAIST) 졸업
                <br />
                서울대학교 치의학대학원 치의학과 졸업
                <br />
                서울대학교 치과병원 인턴
                <br />
                서울대학교 치과병원 소아치과 레지던트
                <br />
                서울대학교 치과대학 치의학 석사
                <br />
                서울대학교 치과대학 소아치과학 박사 수료
                <br />
                대한소아치과학회 정회원
                <br />
                대한장애인치과학회 정회원
              </div>
            </div>
          </HorizontalLayout>
        </Max1280>
      </div>

      <div style={{ background: "#fffcf5", padding: "60px 21px" }}>
        <Max1280>
          <SectionTitle>치과 둘러보기</SectionTitle>
          <Space height={30} />

          <img
            src="/desktop/photo.png"
            style={{ width: "100%", height: "auto" }}
          />
        </Max1280>
      </div>

      <div style={{ background: "#fff", padding: "40px 21px" }}>
        <Max1280>
          <SectionTitle>진료과목</SectionTitle>
          <Space height={25} />

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "58px",
            }}
          >
            <SubjectIcon imageSrc="/common/subject_1.png">
              구강검진
              <br />
              영유아 구강검진
            </SubjectIcon>
            <SubjectIcon imageSrc="/common/subject_2.png">
              충치예방치료
              <br />
              충치치료
            </SubjectIcon>
            <SubjectIcon imageSrc="/common/subject_3.png">
              수면진정치료
            </SubjectIcon>
            <SubjectIcon imageSrc="/common/subject_4.png">
              성장기 교정치료
            </SubjectIcon>
            <SubjectIcon imageSrc="/common/subject_5.png">외상치료</SubjectIcon>
            <SubjectIcon imageSrc="/common/subject_6.png">
              구강내소수술
            </SubjectIcon>
          </div>
        </Max1280>
      </div>

      <div style={{ background: "#fff", padding: "40px 21px 10px" }}>
        <Max1280>
          <SectionTitle id="schedule">진료안내</SectionTitle>
          <Space height={25} />

          <div
            style={{
              position: "relative",
              maxWidth: "970px",
              boxSizing: "border-box",
              margin: "0px auto",
              display: "grid",
              gridTemplateColumns: "350px 1fr",
              gap: "50px",
              background: "#8faa3b",
              borderRadius: "20px",
              padding: "32px 28px",
            }}
          >
            <img
              src="/common/deco3.png"
              style={{
                position: "absolute",
                right: "21px",
                top: "-95px",
                height: "140px",
              }}
            />

            <VerticalLayout>
              <HorizontalLayout align="center">
                <img src="/common/info_clock.png" style={{ width: "34px" }} />
                <div
                  style={{
                    color: "#e2ff5c",
                    fontSize: "22px",
                    fontWeight: "bold",
                    marginLeft: "7px",
                  }}
                >
                  진료시간
                </div>
              </HorizontalLayout>
              <Space height={15} />

              <div
                style={{
                  background: "white",
                  borderRadius: "10px",
                  padding: "15px",
                }}
              >
                <HorizontalLayout align="center">
                  <Badge backgroundColor="#8faa3b" color="#e2ff5c">
                    평일
                  </Badge>
                  <Space width={12} />
                  <div
                    style={{
                      fontSize: "21px",
                      fontWeight: "bold",
                      color: "#6f8823",
                      transform: "translateY(2px)",
                    }}
                  >
                    오전 10:00 – 오후 06:30
                  </div>
                </HorizontalLayout>
                <Space height={10} />

                <HorizontalLayout align="center">
                  <Badge backgroundColor="#386baa" color="#8cddff">
                    토요일
                  </Badge>
                  <Space width={12} />
                  <div
                    style={{
                      fontSize: "21px",
                      fontWeight: "bold",
                      color: "#386baa",
                      transform: "translateY(14px)",
                      textAlign: "left",
                    }}
                  >
                    오전 09:00 – 오후 02:00
                    <br />
                    <span style={{ fontSize: "16px" }}>
                      (토요일은 점심시간 없음)
                    </span>
                  </div>
                </HorizontalLayout>
                <Space height={20} />

                <HorizontalLayout align="center">
                  <Badge backgroundColor="#e97f2d" color="#fee7ba">
                    점심시간
                  </Badge>
                  <Space width={12} />
                  <div
                    style={{
                      fontSize: "21px",
                      fontWeight: "bold",
                      color: "#e97f2d",
                      transform: "translateY(2px)",
                    }}
                  >
                    오후 12:30 – 오후 02:00
                  </div>
                </HorizontalLayout>
                <Space height={41} />

                <div
                  style={{
                    textAlign: "center",
                    fontSize: "21px",
                    fontWeight: "800",
                    transform: "translateX(-20px)",
                  }}
                >
                  일요일, 공휴일은{" "}
                  <span style={{ color: "#c70000" }}>휴진</span>
                  입니다.
                  <div style={{ position: "relative" }}>
                    <img
                      src="/common/deco1.png"
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "-45px",
                        width: "35px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </VerticalLayout>

            <VerticalLayout>
              <HorizontalLayout align="start">
                <img
                  src="/common/info_calendar.png"
                  style={{ width: "34px" }}
                />
                <Space width={18} />

                <VerticalLayout style={{ transform: "translateY(7px)" }}>
                  <div
                    style={{
                      width: "max-content",
                      color: "#e2ff5c",
                      fontSize: "22px",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    예약제도 안내
                    <div style={{ position: "relative" }}>
                      <img
                        src="/common/deco2.png"
                        style={{
                          position: "absolute",
                          right: "-110px",
                          top: "-50px",
                          width: "85px",
                        }}
                      />
                    </div>
                  </div>

                  <Space height={21} />

                  <div
                    style={{
                      fontSize: "21px",
                      color: "white",
                      lineHeight: "1.47",
                      textAlign: "left",
                    }}
                  >
                    서울참어린이치과는,
                    <br />
                    어린이 한명 한명에게 최선을 다해 진료하기 위하여, <br />
                    보호자분들의 대기시간을 단축시켜 드리기 위하여,
                    <br />
                    <span
                      style={{
                        background: "#799426",
                        padding: "3px",
                        fontWeight: "800",
                      }}
                    >
                      진료 예약제
                    </span>
                    를 시행하고 있습니다.
                  </div>
                </VerticalLayout>
              </HorizontalLayout>

              <Space height={40} />

              <HorizontalLayout align="start" id="contact">
                <img src="/common/info_phone.png" style={{ width: "34px" }} />
                <Space width={18} />

                <VerticalLayout style={{ transform: "translateY(7px)" }}>
                  <div
                    style={{
                      color: "#e2ff5c",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    예약•상담 문의
                  </div>
                  <Space height={21} />

                  <div
                    style={{
                      color: "white",
                      fontSize: "46px",
                      fontWeight: "800",
                      textShadow: "3px 3px #7e9929",
                    }}
                  >
                    051-701-2879
                  </div>
                </VerticalLayout>
              </HorizontalLayout>
            </VerticalLayout>
          </div>
        </Max1280>
      </div>

      <div style={{ background: "#fff", padding: "40px 21px" }}>
        <Max1280>
          <SectionTitle id="location">오시는길</SectionTitle>
          <Space height={42} />

          <HorizontalLayout style={{ justifyContent: "center" }}>
            <img
              src="/mobile/map.png"
              style={{ width: "391px", height: "auto" }}
              onClick={() => window.open(MapURL, "_blank")}
            />
            <Space width={38} />

            <VerticalLayout style={{ gap: "36px" }}>
              <HorizontalLayout>
                <img
                  src="/common/location.png"
                  style={{ width: "56px", height: "56px" }}
                />
                <Space width={10} />
                <VerticalLayout style={{ marginTop: "14px" }}>
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "800",
                      color: "#45c1df",
                    }}
                  >
                    주소
                  </div>
                  <Space height={14} />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "1.73",
                    }}
                  >
                    부산광역시 해운대구 해운대로 814, 세종월드프라자 A동 4층
                    <br />
                    <span style={{ color: "#52ac00" }}>
                      지하철 2호선 장산역 3번 출구
                    </span>
                  </div>
                </VerticalLayout>
              </HorizontalLayout>

              <HorizontalLayout>
                <img
                  src="/common/car.png"
                  style={{ width: "56px", height: "56px" }}
                />
                <Space width={10} />
                <VerticalLayout style={{ marginTop: "14px" }}>
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "800",
                      color: "#45c1df",
                    }}
                  >
                    주차 안내
                  </div>
                  <Space height={14} />
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      lineHeight: "1.73",
                    }}
                  >
                    <span style={{ fontSize: "18px" }}>동 건물 지하주차장</span>
                    <br />
                    주차권에 병원 도장을 찍어가시면 동 건물 지하주차장 2시간
                    무료입니다
                  </div>
                </VerticalLayout>
              </HorizontalLayout>

              <HorizontalLayout>
                <img
                  src="/common/phone.png"
                  style={{ width: "56px", height: "56px" }}
                />
                <Space width={10} />
                <VerticalLayout style={{ marginTop: "14px" }}>
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "800",
                      color: "#45c1df",
                    }}
                  >
                    예약, 상담 문의
                  </div>
                  <Space height={14} />
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      lineHeight: "1.33",
                    }}
                    onClick={() => window.open(`tel:${PhoneNumber}`)}
                  >
                    TEL. 051-701-2879
                  </div>
                </VerticalLayout>
              </HorizontalLayout>
            </VerticalLayout>
          </HorizontalLayout>
        </Max1280>
      </div>
      <Space height={64} />

      <Footer />

      <MainPopup />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 900px;
`;

const Max1280 = styled.div.attrs({
  className: "max1280",
})`
  width: 100%;
  max-width: 1280px;

  margin: 0px auto;
  padding: 0px 64px;

  box-sizing: border-box;
`;

const MainBanner = styled.img.attrs({})`
  width: 100%;
  max-width: 1280px;

  height: auto;

  margin: 0px auto;
`;

const SectionTitle = styled.div<{ color?: string }>`
  font-size: 30px;
  font-weight: bold;

  color: ${({ color }) => color ?? "#313131"};
`;
