import React from "react";
import { styled } from "styled-components";

export const Header = () => {
  return (
    <Container>
      <Logo src="/mobile/logo.png" />
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;

  padding: 19px 17px;
`;

const Logo = styled.img.attrs({})`
  width: 150px;
  height: auto;
`;
