import React, { useState } from "react";
import styled from "styled-components";

interface MainPopupProps {}
export const MainPopup = ({}: MainPopupProps) => {
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <Backdrop onClick={() => setShow(false)}>
      <Container>
        <Image src="https://firebasestorage.googleapis.com/v0/b/seoul-cham.appspot.com/o/popup.png?alt=media&token=50b5341d-5998-4da6-8646-e55b2ee68117" />
      </Container>
    </Backdrop>
  );
};

const Backdrop = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.3);
`;

const Container = styled.div`
  display: flex;

  cursor: pointer;
`;
const Image = styled.img`
  width: 320px;
  height: auto;
`;
