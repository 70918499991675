import React from 'react'
import styled, { css } from 'styled-components'

interface HorizontalLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  fill?: boolean
  align?: 'none' | 'center' | 'start' | 'end'
  children: React.ReactNode
}
export const HorizontalLayout = ({
  fill = false,
  align,
  children,
  ...props
}: HorizontalLayoutProps) => {
  return (
    <Container fill={fill} align={align} {...props}>
      {children}
    </Container>
  )
}

const Container = styled.div<Partial<HorizontalLayoutProps>>`
  display: flex;
  flex-direction: row;

  ${({ fill }) =>
    fill
      ? css`
          flex: 1;
        `
      : css``}
  ${({ align }) =>
    ({
      none: css``,
      start: css`
        align-items: flex-start;
      `,
      end: css`
        align-items: flex-end;
      `,
      center: css`
        align-items: center;
      `
    }[align!])}
`
