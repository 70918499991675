import React from "react";
import styled, { css } from "styled-components";

import { Space } from "../layout";
import { isMobile } from "../../util";

interface SubjectIconProps {
  imageSrc: string;
  children: React.ReactNode;
}
export const SubjectIcon = ({ imageSrc, children }: SubjectIconProps) => {
  const mobile = isMobile();

  return (
    <Container isMobile={mobile}>
      <img src={imageSrc} style={{ height: mobile ? "40px" : "82px" }} />
      <Space height={mobile ? 13 : 38} />
      {children}
    </Container>
  );
};

const Container = styled.div<{ isMobile: boolean }>`
  flex: 1;
  aspect-ratio: 1;

  display: flex;
  flex-direction: column;

  font-size: 17px;
  font-weight: bold;
  text-align: center;

  color: #2f2f2f;

  background-color: #f1f1f1;
  border-radius: 200px;

  align-items: center;
  justify-content: center;

  ${({ isMobile }) =>
    isMobile
      ? css``
      : css`
          font-size: 27px;
          line-height: 1.24;
        `}
`;
