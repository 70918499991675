import React from "react";
import { DesktopPage } from "./page/desktop";
import { MobilePage } from "./page/mobile";
import { isMobile } from "./util";

const App = () => {
  if (isMobile()) {
    return <MobilePage />;
  }

  return <DesktopPage />;
};

export default App;
