import React from 'react'

import { EnvKind, useEnv } from '../api'

type Variant = {
  mobile: number | string
  desktop: number | string
}

interface SpaceProps {
  width?: number | string | Variant
  height?: number | string | Variant
}
export const Space = React.memo(({ width = 1, height = 1, ...props }: SpaceProps) => {
  const env = useEnv()

  return (
    <div
      style={{
        width: resolveValue(env, width),
        height: resolveValue(env, height)
      }}
      {...props}
    />
  )
})

const resolveValue = (env: EnvKind, value: number | string | Variant) => {
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'number') {
    return `${value}px`
  }

  return env === EnvKind.Mobile ? value.mobile : value.desktop
}
