import React from "react";
import styled from "styled-components";

interface BadgeProps {
  backgroundColor: string;
  color: string;
  children: React.ReactNode;
}
export const Badge = ({ backgroundColor, color, children }: BadgeProps) => {
  return (
    <Container backgroundColor={backgroundColor} color={color}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ backgroundColor: string; color: string }>`
  display: flex;
  width: 70px;

  font-size: 14px;
  font-weight: bold;

  padding: 10px 0px;

  align-items: center;
  justify-content: center;

  border-radius: 100px;

  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;
