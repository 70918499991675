import React from "react";
import { styled } from "styled-components";

import { HorizontalLayout, Space, VerticalLayout } from "../layout";

export const Footer = () => {
  return (
    <Container>
      <InnerContent>
        <Logo src="/desktop/logo.png" />
        <Space width={17} />
        <VerticalLayout>
          <HorizontalLayout>
            상호명 : 서울참어린이치과의원
            <Space width={50} />
            대표자명 : 김아람
            <Space width={50} />
            사업자 등록번호 : 350-90-00283
          </HorizontalLayout>
          <HorizontalLayout>
            주소 : 부산시 해운대구 해운대로 814 세종월드프라자 4층
            <Space width={50} />
            전화 : 051-701-2879
            <Space width={50} />
            FAX : 051-701-2878
          </HorizontalLayout>
          <Space height={21} />
          <div>
            Copyright <b>ⓒ서울참어린이치과의원</b> All rights reserved
          </div>
        </VerticalLayout>
      </InnerContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  background-color: #f4f4f4;

  color: #454545;

  font-size: 16px;
  line-height: 1.71;
  letter-spacing: -0.7px;

  padding: 26px 18px;

  align-items: center;
  justify-content: center;
`;
const InnerContent = styled.div`
  display: flex;

  width: 100%;
  max-width: 1280px;

  justify-content: center;
`;

const Logo = styled.img.attrs({})`
  width: 170px;
  height: 28px;
`;
